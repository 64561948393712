import axios from "axios";

const URL = "https://api.dev.hiveco.space/api/v1/contact";

export const sendMessage = async (name, email, message) => {
  const response = await axios.post(URL, {
    name,
    email,
    message,
  });

  return response.data;
};
