import React from "react";
import styles from "./SideMenu.module.css";
import { Link } from "react-router-dom";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import BurgerButton from "../BurgerButton/BurgerButton";
import { useDeviceWidth } from "../../hooks";
import { containerVariant, linkVariant, PAGES } from "./SideMenu.config";

import HLetter from "../../assets/images/imgs/letter-h.svg";
import VELetters from "../../assets/images/imgs/letters-ve.svg";
import { HomeIcon, HomeIconTr } from "../../assets";
import Socials from "../Socials/Socials";

const SideMenu = () => {
  const [open, cycleOpen] = useCycle(false, true);
  const { windowWidth } = useDeviceWidth();

  const menuVariant = {
    unmount: { opacity: 0, delay: 1 },
    mount: { opacity: 1, delay: 1 },
  };

  return windowWidth > 992 ? (
    <motion.aside
      className={styles.wrapper}
      variants={menuVariant}
      initial="unmount"
      animate="mount"
      exit="unmount"
    >
      <img src={HLetter} alt="H" className="letters" />
      <div className={styles.container}>
        <div className={styles.burger} onClick={cycleOpen}>
          <BurgerButton isActive={open} />
        </div>
        <AnimatePresence>
          {open && (
            <motion.div
              className={styles.nav_container}
              variants={containerVariant}
              initial="hidden"
              animate="show"
              exit="hidden"
            >
              <nav className={styles.navigation}>
                {PAGES.map((each) => (
                  <motion.span
                    variants={linkVariant}
                    key={each.name}
                    className={styles.page_span}
                  >
                    <Link
                      to={each.to}
                      onClick={cycleOpen}
                      className={styles.nav_link}
                    >
                      {each.name}
                    </Link>
                  </motion.span>
                ))}

                <div className={styles.home}>
                  <Link
                    to="/"
                    className={styles.home_btn}
                    variants={linkVariant}
                    onClick={cycleOpen}
                  >
                    <HomeIcon className={styles.home_icon_active} />
                    <HomeIconTr className={styles.home_icon} />
                  </Link>
                </div>

                <Socials className={styles.socials} />
              </nav>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <img src={VELetters} alt="VE" className="letters" />
    </motion.aside>
  ) : null;
};

export default SideMenu;
