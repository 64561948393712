export const firstFacilitiesData = {
  title: "6,000",
  sup: "m2",
  subtitle: "to meet your work/life balance",
  data: [
    {
      index: "A",
      title: "Telephone Booths. ",
      text: "Soundproofed one-person meeting spaces. ",
      tag: "#1300",
    },
    {
      index: "B",
      title: "Kitchens + Dinning. ",
      text: "Separate spaces prep and lunch. ",
      tag: "#2398",
    },
    {
      index: "C",
      title: "Game & Relax zones. ",
      text: "Cosy nooks and entertainment zones. ",
      tag: "#2398",
    },
  ],
};
