import dedicatedDesk from "../../assets/images/imgs/dedicated-desk.png";
import flexDesk from "../../assets/images/imgs/flex-desk.png";

export const flexDeskData = {
  title: "flex desk",
  src: flexDesk,
  desc: "A shared desk that can be booked daily.",
  price: 30,
  per: "day",
  hoverData: null,
};

export const dedicatedDeskData = {
  title: "dedicated desk",
  src: dedicatedDesk,
  desc: "Fixed desk for the amount of time you need.",
  price: "550",
  per: "month",
  hoverData: [
    {
      id: 1,
      title: null,
      data: [
        {
          id: 10,
          range: "01 week",
          price: 150,
        },
        {
          id: 11,
          range: "10 days",
          price: 180,
        },
        {
          id: 12,
          range: "15 days",
          price: 250,
        },
        {
          id: 13,
          range: "01 month",
          price: 550,
        },
      ],
    },
  ],
};
