import React, { useState, useEffect } from "react";
import { IntroLoader, AppRouter } from "./components";

const App = () => {
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowIntro(false);
    }, 8500);
    return () => {};
  }, []);

  return showIntro ? <IntroLoader /> : <AppRouter />;
};

export default App;
