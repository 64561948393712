import React, { useState } from "react";
import {
  // GreenSquare,
  // LeftAngle,
  RedFlake,
  // RightAngle
} from "../../assets";
import BorderedImage from "../BorderedImage/BorderedImage";
import PageLayout from "../PageLayout/PageLayout";
import { mobileFacilitiesData } from "./MobileFacilities.mock";

import styles from "./MobileFacilities.module.css";

const MobileFacilities = () => {
  const [currentView] = useState(mobileFacilitiesData[0]);

  // const handleNextViewChange = () => {
  //   window.scrollTo(0, 0);
  //   setCurrentView((prev) =>
  //     prev.id < mobileFacilitiesData.length - 1
  //       ? mobileFacilitiesData[prev.id + 1]
  //       : mobileFacilitiesData[0]
  //   );
  // };

  // const handlePrevViewChange = () => {
  //   window.scrollTo(0, 0);
  //   setCurrentView((prev) =>
  //     prev.id > 0
  //       ? mobileFacilitiesData[prev.id - 1]
  //       : mobileFacilitiesData[mobileFacilitiesData.length - 1]
  //   );
  // };

  return (
    <PageLayout>
      <div className={styles.container}>
        {currentView.PAGE_TYPE === "1" ? (
          <div className={styles.first_type}>
            <RedFlake className={styles.flake} />
            <img
              src={currentView.mainImgSrc}
              alt="cover"
              className={styles.cover}
            />
            <h1 className={styles.title}>
              {currentView.title}
              <sup>{currentView.sup}</sup>
            </h1>
            <p className={styles.subtitle}>{currentView.subtitle}</p>

            <ol className={styles.list} type="A">
              {currentView.data.map((el) => (
                <li className={styles.list_item} key={el.index}>
                  <span className={styles.item_title}>{el.title}</span>
                  <span className={styles.item_text}>{el.text}</span>
                  {/* <span className={styles.item_tag}>{el.tag}</span> */}
                </li>
              ))}
            </ol>
          </div>
        ) : (
          <div className={styles.second_type}>
            <img
              src={currentView.mainImgSrc}
              alt="cover"
              className={styles.cover}
            />
            <h1 className={styles.title}>{currentView.title}</h1>
            <h1 className={styles.title}>{currentView.split_title}</h1>
            <p className={styles.subtitle}>{currentView.subtitle}</p>

            <BorderedImage
              src={currentView.scndImageSrc}
              letter=""
              className={styles.className}
            />

            <ol
              className={`${styles.list} ${currentView.PAGE_TYPE === "2" ? styles.custom : ""
                }`}
              type="A"
            >
              {currentView.data.map((el) => (
                <li className={styles.list_item} key={el.index}>
                  <span className={styles.item_title}>{el.title}</span>
                  <span className={styles.item_text}>{el.text}</span>
                  {/* <span className={styles.item_tag}>{el.tag}</span> */}
                </li>
              ))}
            </ol>
          </div>
        )}

        {/* <div className={styles.navigation}>
          <LeftAngle onClick={handlePrevViewChange} className={styles.arrows} />
          <div className={styles.box}>
            next
            <GreenSquare
              className={styles.square}
              onClick={handleNextViewChange}
            />
          </div>
          <RightAngle
            onClick={handleNextViewChange}
            className={styles.arrows}
          />
        </div> */}
      </div>
    </PageLayout>
  );
};

export default MobileFacilities;
