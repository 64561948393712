import React from "react";

import styles from "./BorderedImage.module.css";

const BorderedImage = ({
  src = "",
  alt = "",
  letter = "B.",
  className = "",
}) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <span className={styles.letter}>{letter}</span>
      <img src={src} alt={alt} className={styles.image} />
    </div>
  );
};

export default BorderedImage;
