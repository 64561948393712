import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { Close } from "../../assets";

import styles from "./Modal.module.css";

const Modal = ({ message, onClose, type = "message" }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return createPortal(
    <div className={styles.container} onClick={onClose}>
      <div className={styles.window}>
        <Close className={styles.close} onClick={onClose} />
        {
          (type = "error" ? (
            <p className={styles.error}>{message}</p>
          ) : (
            { message }
          ))
        }
      </div>
    </div>,
    document.body
  );
};

export default Modal;
