import React, { useState } from "react";
import { CONTACT_INFO } from "../../data/ContactInfo/ContactInfo";

import styles from "./ServiceCard.module.css";

const ServiceCard = ({
  isTall = false,
  src,
  title,
  desc,
  price,
  per,
  call,
  hoverData = null,
  isMobileCard = false,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleHover = () => {
    if (hoverData) {
      setIsHovering(true);
    }
  };

  const handleDisableHover = () => {
    if (hoverData) {
      setIsHovering(false);
    }
  };

  return (
    <div
      className={`${styles.container} ${isTall && styles.tall} ${
        isMobileCard && styles.mobile
      }`}
      onMouseEnter={handleHover}
      onMouseLeave={handleDisableHover}
    >
      <div className={styles.card}>
        <img src={src} alt={title} className={styles.card_img} />

        <div className={styles.card_description}>
          <h3>{title}</h3>
          <p>{desc}</p>
          <div className={styles.total}>
            <span className={styles.price}>
              {call ? (
                <a href={`tel:${CONTACT_INFO.mobile}`} className={styles.call}>
                  Call
                </a>
              ) : (
                price
              )}
            </span>
            <span className={styles.per}>₾/{per}</span>
          </div>
        </div>
      </div>

      {hoverData && (
        <div className={`${styles.hover} ${isHovering && styles.opacity}`}>
          {hoverData.map((el) => (
            <div key={el.id} className={styles.hover_cont}>
              <p className={styles.hover_title}>{el?.title}</p>
              {el?.data.map((each) => (
                <div className={styles.hover_data} key={each.id}>
                  <span className={styles.hover_range}>{each.range}</span>
                  <span className={styles.hover_price}>
                    {each.price}
                    <sup> ₾</sup>
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ServiceCard;
