import React from "react";
import { useLottie } from "lottie-react";
import animation from "./animation.json";

import styles from "./IntroLoader.module.css";

const IntroLoader = () => {
  const options = {
    animationData: animation,
    loop: true,
  };

  const { View } = useLottie(options);

  return <div className={styles.container}>{View}</div>;
};

export default IntroLoader;
