import privateOffice from "../../assets/images/imgs/mobile-private-office.png";
import meetingRooms from "../../assets/images/imgs/mobile-meeting-rooms.png";
import conferenceRooms from "../../assets/images/imgs/mobile-conference-rooms.png";
import dedicatedDesk from "../../assets/images/imgs/mobile-dedicated-desk.png";
import flexDesk from "../../assets/images/imgs/mobile-flex-desk.png";

export const mobileServicesData = [
  {
    id: 0,
    letter: "A",
    mainTitle: "memberships",
    subtitle: "Join Hive on your terms",
    description:
      "Whether you're here for the long run or just passing through. We have plans to suit everyone.",
    title: "flex desk",
    src: flexDesk,
    desc: "A shared desk that can be booked daily.",
    price: 30,
    per: "day",
    hoverData: null,
  },
  {
    id: 1,
    letter: "B",
    mainTitle: "memberships",
    subtitle: "Hive meets the needs of your gatherings",
    description:
      "We offer a variety of spaces to accommodate meetings of any size, ranging from intimate and comfortable settings to expansive indoor venues suitable for larger conferences. ",
    title: "dedicated desk",
    src: dedicatedDesk,
    desc: "Fixed desk for the amount of time you need.",
    price: "550",
    per: "month",
    hoverData: [
      {
        id: 1,
        title: null,
        data: [
          {
            id: 10,
            range: "01 week",
            price: 150,
          },
          {
            id: 11,
            range: "10 days",
            price: 180,
          },
          {
            id: 12,
            range: "15 days",
            price: 250,
          },
          {
            id: 13,
            range: "01 month",
            price: 550,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    letter: "C",
    mainTitle: "memberships",
    subtitle: "Hive meets the needs of your gatherings",
    description:
      "We offer a variety of spaces to accommodate meetings of any size, ranging from intimate and comfortable settings to expansive indoor venues suitable for larger conferences. ",
    title: "private Office",
    desc: "Quiet, secure spaces for long-term use.",
    src: privateOffice,
    call: true,
    price: null,
    per: "month",
    hoverData: null,
  },
  {
    id: 3,
    letter: "D",
    mainTitle: "services",
    subtitle: "Hive meets the needs of your gatherings",
    description:
      "We offer a variety of spaces to accommodate meetings of any size, ranging from intimate and comfortable settings to expansive indoor venues suitable for larger conferences. ",
    title: "meeting rooms",
    desc: "cozy room for events and meetings",
    price: 30,
    per: "hour",
    src: meetingRooms,
    hoverData: [
      {
        id: 0,
        title: "2-6 person room",
        data: [
          { range: "1 hour", price: 30, id: 10 },
          { range: "half day", price: 150, id: 11 },
          { range: "full day", price: 200, id: 12 },
        ],
      },
      {
        id: 1,
        title: "7-10 person room",
        data: [
          { range: "1 hour", price: 40, id: 21 },
          { range: "half day", price: 200, id: 22 },
          { range: "full day", price: 300, id: 23 },
        ],
      },
    ],
  },
  {
    id: 4,
    letter: "E",
    mainTitle: "services",
    subtitle: "Join Hive on your terms",
    description:
      "Whether you're here for the long run or just passing through. We have plans to suit everyone.",
    title: "conference rooms",
    desc: "for formal & informal meetings and events",
    price: 80,
    per: "day",
    src: conferenceRooms,
    hoverData: [
      {
        id: 2,
        title: "Up to 40 Person Conference",
        data: [
          { range: "1 hour", price: 80, id: 31 },
          { range: "half day", price: 300, id: 32 },
          { range: "full day", price: 500, id: 33 },
        ],
      },
      {
        id: 3,
        title: "Up to 80 Person Conference",
        data: [
          { range: "1 hour", price: 150, id: 41 },
          { range: "half day", price: 400, id: 42 },
          { range: "full day", price: 750, id: 43 },
        ],
      },
    ],
  },
];
