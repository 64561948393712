export const containerVariant = {
  hidden: { width: 0 },
  show: {
    width: "calc(100vw - 131px)",
  },
};

export const linkVariant = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
};

export const PAGES = [
  {
    name: "facilities",
    to: "/facilities",
  },
  {
    name: "location",
    to: "/location",
  },
  {
    name: "services",
    to: "/services",
  },
  {
    name: "contact",
    to: "/contact",
  },
];
