import React, { useState } from "react";
import {
  Loader,
  MemeberShips,
  MobileServices,
  Services,
} from "../../components";
import { useDeviceWidth } from "../../hooks";

const ServicesPage = () => {
  const [activeSection, setActiveSection] = useState("service");
  const [isLoading, setIsLoading] = useState(false);
  const { windowWidth } = useDeviceWidth();

  const toggleActiveSection = () => {
    setIsLoading(true);
    setTimeout(() => {
      setActiveSection((prevState) =>
        prevState === "service" ? "memebrship" : "service"
      );
    }, 500);
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      {windowWidth > 992 ? (
        activeSection === "service" ? (
          <Services onClick={toggleActiveSection} />
        ) : (
          <MemeberShips onClick={toggleActiveSection} />
        )
      ) : (
        <MobileServices />
      )}
    </>
  );
};

export default ServicesPage;
