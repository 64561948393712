import React from "react";
import { GreenFlake, LocationIcon, RedFlake } from "../../assets";
import { ContactForm, PageLayout, Socials } from "../../components";
import contact from "../../assets/images/imgs/contact.png";
import { useDeviceWidth } from "../../hooks";

import styles from "./ContactPage.module.css";
import { CONTACT_INFO } from "../../data/ContactInfo/ContactInfo";

const ContactPage = () => {
  const { windowWidth } = useDeviceWidth();
  return (
    <PageLayout>
      <div className={styles.container}>
        <div className={styles.left}>
          {windowWidth > 992 ? (
            <h1 className={styles.title}>
              <RedFlake className={styles.red_flake} />
              get in touch
            </h1>
          ) : (
            <>
              <h1 className={styles.title}>get in</h1>
              <h1 className={styles.title}>touch</h1>
            </>
          )}
          <ContactForm />
          <div className={styles.contact}>
            <div className={styles.cont_l}>
              <p className={styles.cont_title}>Hive Coworking.</p>
              <p className={styles.cont_address}>
                13 Mikheil Tamarashvili. Tbilisi <span>0162</span>
              </p>
              {windowWidth < 992 && (
                <a className={styles.map} href={CONTACT_INFO.map}>
                  <LocationIcon />
                  <span>open maps</span>
                </a>
              )}
            </div>
            <div className={styles.cont_r}>
              <a href={`tel:${CONTACT_INFO.mobile}`} className={styles.mobile}>
                +995 511 10 55 00
              </a>
              <a href={`mailto:${CONTACT_INFO.email}`} className={styles.email}>
                {CONTACT_INFO.email}
              </a>
            </div>
          </div>
          <Socials isWhite />
        </div>
        <div className={styles.right}>
          <GreenFlake className={styles.green_flake} />
          <img src={contact} alt="contact" className={styles.cover} />
        </div>
      </div>
    </PageLayout>
  );
};

export default ContactPage;
