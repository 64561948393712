import React from "react";

import styles from "./Loader.module.css";

const Loader = ({ isLoading }) => {
  return (
    <div className={`${styles.container} ${isLoading ? styles.active : ""}`} />
  );
};

export default Loader;
