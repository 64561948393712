import privateOffice from "../../assets/images/imgs/private-office.png";
import meetingRooms from "../../assets/images/imgs/meeting-rooms.png";
import conferenceRooms from "../../assets/images/imgs/conference-rooms.png";

export const meetingRoomsData = {
  title: "meeting rooms",
  desc: "cozy room for events and meetings",
  price: 30,
  per: "hour",
  src: meetingRooms,
  hoverData: [
    {
      id: 0,
      title: "2-6 person room",
      data: [
        { range: "1 hour", price: 30, id: 10 },
        { range: "half day", price: 150, id: 11 },
        { range: "full day", price: 200, id: 12 },
      ],
    },
    {
      id: 1,
      title: "7-10 person room",
      data: [
        { range: "1 hour", price: 40, id: 21 },
        { range: "half day", price: 200, id: 22 },
        { range: "full day", price: 300, id: 23 },
      ],
    },
  ],
};

export const conferenceRoomsData = {
  title: "conference rooms",
  desc: "for formal & informal meetings and events",
  price: 80,
  per: "day",
  src: conferenceRooms,
  hoverData: [
    {
      id: 2,
      title: "Up to 40 Person Conference",
      data: [
        { range: "1 hour", price: 80, id: 31 },
        { range: "half day", price: 300, id: 32 },
        { range: "full day", price: 500, id: 33 },
      ],
    },
    {
      id: 3,
      title: "Up to 80 Person Conference",
      data: [
        { range: "1 hour", price: 150, id: 41 },
        { range: "half day", price: 400, id: 42 },
        { range: "full day", price: 750, id: 43 },
      ],
    },
  ],
};

export const privateOfficeData = {
  title: "private Office",
  desc: "Quiet, secure spaces for long-term use.",
  src: privateOffice,
  call: true,
  price: null,
  per: "month",
  hoverData: null,
};
