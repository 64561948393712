export const sliderTextData = [
  {
    id: 0,
    title: "0 mins",
    mainText: "Walk to our 800m2 garden.",
    secondaryText: "Because it's our garden in our own yard.",
  },
  {
    id: 1,
    title: "5 mins",
    mainText: "Walk to Central Park.",
    secondaryText: "If our garden is not enough for you.",
  },
  {
    id: 2,
    title: "7 mins",
    mainText: "Walk to Delisi Metro.",
    secondaryText: "For transport and stuff.",
  },
];
