import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HLetter from "../../assets/images/imgs/letter-h.svg";
import VELetters from "../../assets/images/imgs/letters-ve.svg";

import styles from "./Logo.module.css";

const Logo = () => {
  const [width, setWidth] = useState("2px");
  const { pathname } = useLocation();

  useEffect(() => {
    switch (pathname) {
      case "/":
        setWidth("2px");
        break;
      case "/facilities":
        setWidth("16px");
        break;
      case "/location":
        setWidth("36px");
        break;
      case "/services":
        setWidth("56px");
        break;
      case "/contact":
        setWidth("96px");
        break;
      default:
        setWidth("2px");
    }
  }, [pathname]);

  return (
    <Link to="/" className={styles.logo}>
      <img src={HLetter} alt="H" className={styles.img} />
      <div className={styles.block} style={{ width: width }}></div>
      <img src={VELetters} alt="VE" className={styles.img} />
    </Link>
  );
};

export default Logo;
