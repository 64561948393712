import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CONTACT_INFO } from "../../data/ContactInfo/ContactInfo";
import { useDeviceWidth } from "../../hooks";
import { motion } from "framer-motion";
import BurgerButton from "../BurgerButton/BurgerButton";
import Logo from "../Logo/Logo";
import Socials from "../Socials/Socials";

import styles from "./Header.module.css";

const Header = () => {
  const { windowWidth } = useDeviceWidth();
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => setShowMenu((prevState) => !prevState);

  const headerVariant = {
    unmount: { opacity: 0, delay: 1 },
    mount: { opacity: 1, delay: 1 },
  };

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showMenu]);

  return windowWidth < 992 ? (
    <motion.header
      className={styles.header}
      variants={headerVariant}
      initial="unmount"
      animate="mount"
      exit="unmount"
    >
      <Logo />

      <BurgerButton isTiny isActive={showMenu} onClick={toggleMenu} />

      <div className={`${styles.menu} ${showMenu ? styles.active : ""}`}>
        <nav className={styles.navigation} onClick={toggleMenu}>
          <Link to="/facilities" className={styles.navlink}>
            FACILI- <br /> TIES
          </Link>
          <Link to="/location" className={styles.navlink}>
            LOCATION
          </Link>
          <Link to="/services" className={styles.navlink}>
            SERVICES
          </Link>
          <Link to="/contact" className={styles.navlink}>
            CONTACT
          </Link>
        </nav>

        <p className={styles.title}>Hive Co.Working</p>
        <p className={styles.address}>13 Mikheil Tamarashvili. Tbilisi 0162</p>
        <a
          href={`${CONTACT_INFO.map}`}
          target="_blank"
          rel="noreferrer"
          className={styles.map}
        >
          OPEN MAPS
        </a>
        <div className={styles.contact}>
          <a href={`tel:${CONTACT_INFO.mobile}`} className={styles.mobile}>
            +995 511 10 55 00
          </a>
          <a href={`mailto:${CONTACT_INFO.email}`} className={styles.email}>
            {CONTACT_INFO.email}
          </a>
        </div>

        <Socials />
      </div>
    </motion.header>
  ) : null;
};

export default Header;
