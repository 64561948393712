import React from "react";
import { PageLayout } from "../../components";
import home from "../../assets/images/imgs/home_page.png";
import { useDeviceWidth } from "../../hooks";

import styles from "./HomePage.module.css";
import { GreenFlake } from "../../assets";

const HomePage = () => {
  const { windowWidth } = useDeviceWidth();
  return (
    <PageLayout>
      <div className={styles.container}>
        <div className={styles.left}>
          <h6 className={styles.head_title}>work from</h6>
          {windowWidth > 992 ? (
            <h1 className={styles.title}>home</h1>
          ) : (
            <>
              <h1 className={styles.title}>ho</h1>
              <h1 className={styles.title}>me</h1>
            </>
          )}
          <p className={styles.subtitle}>
            Hive is the biggest co-working space in Tbilisi.
          </p>

          <p className={styles.text}>
            Carefully conceptualised and handcrafted to meet the need of anyone.
            Whether you're a freelancer, a remote worker, a start-up or a big
            company, Hive is an ideal place for you. It can accommodate your
            every need and even more.
          </p>
        </div>
        <div className={styles.right}>
          <GreenFlake className={styles.flake} />
          <img src={home} alt="cover" className={styles.cover} />
        </div>
      </div>
    </PageLayout>
  );
};

export default HomePage;
