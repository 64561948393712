import React from "react";
import PageLayout from "../PageLayout/PageLayout";
import ServiceCard from "../ServiceCard/ServiceCard";

import styles from "./Services.module.css";
import { DoubleArrow, GreenRectangle, SignleArrow } from "../../assets";
import {
  conferenceRoomsData,
  meetingRoomsData,
  privateOfficeData,
} from "./Services.mock";

const Services = ({ onClick }) => {
  return (
    <PageLayout>
      <div className={styles.container}>
        <div className={styles.desc}>
          <h1>services</h1>
          <h6>Hive meets the needs of your gatherings</h6>
          <p>
            We offer a variety of spaces to accommodate meetings of any size, ranging from intimate and
            comfortable settings to expansive indoor venues suitable for larger conferences.
          </p>
          <div
            className={styles.actions}
            onClick={onClick}
            role="button"
            tabIndex={0}
          >
            <div className={styles.button}>
              membership
              <GreenRectangle className={styles.rect} />
            </div>
            <SignleArrow className={styles.single} />
            <DoubleArrow className={styles.double} />
          </div>
        </div>

        <div className={styles.right}>
          <ServiceCard
            isTall
            src={privateOfficeData.src}
            title={privateOfficeData.title}
            desc={privateOfficeData.desc}
            price={privateOfficeData.price}
            call={privateOfficeData?.call}
            per={privateOfficeData.per}
          />

          <div>
            <ServiceCard
              src={meetingRoomsData.src}
              title={meetingRoomsData.title}
              desc={meetingRoomsData.desc}
              price={meetingRoomsData.price}
              per={meetingRoomsData.per}
              hoverData={meetingRoomsData.hoverData}
            />

            <ServiceCard
              src={conferenceRoomsData.src}
              title={conferenceRoomsData.title}
              desc={conferenceRoomsData.desc}
              price={conferenceRoomsData.price}
              per={conferenceRoomsData.per}
              hoverData={conferenceRoomsData.hoverData}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Services;
