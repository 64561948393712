import React from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import {
  ContactPage,
  FacilitiesPage,
  HomePage,
  LocationPage,
  ServicesPage,
} from "../../pages";
import Header from "../Header/Header";
import SideMenu from "../SideMenu/SideMenu";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Header />
      <SideMenu />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/facilities" element={<FacilitiesPage />} />
        <Route path="/location" element={<LocationPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
