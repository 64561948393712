import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Check, Checked } from "../../assets";
import Modal from "../Modal/Modal";
import { validationRules } from "./ContactForm.config";
import { sendMessage } from "../../api/sendMessage";

import styles from "./ContactForm.module.css";

const ContactForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [hasError, setHasError] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onBlur" });

  const handleSubmitMessage = async ({ name, email, message }) => {
    try {
      await sendMessage(name, email, message);
      setShowModal(true);
      setIsChecked(false);
      reset();
    } catch (error) {
      setHasError(true);
    }
  };

  const toggleCheck = () => setIsChecked((prevState) => !prevState);

  const handleModalClose = () => {
    setShowModal(false);
    setHasError(false);
  };

  return (
    <>
      {showModal && (
        <Modal
          message={"message sent"}
          onClose={handleModalClose}
          type="message"
        />
      )}
      {hasError && (
        <Modal
          message={"something went wrong, please try again"}
          onClose={handleModalClose}
          type="error"
        />
      )}
      <form
        onSubmit={handleSubmit(handleSubmitMessage)}
        className={styles.container}
      >
        <div className={styles.row}>
          <div className={styles.input_cont}>
            <input
              {...register("name", { ...validationRules.name })}
              placeholder="Name"
            />
            <span className={styles.error}>{errors?.name?.message}</span>
          </div>
          <div className={styles.input_cont}>
            <input
              {...register("email", { ...validationRules.email })}
              placeholder="Email"
            />
            <span className={styles.error}>{errors?.email?.message}</span>
          </div>
        </div>
        <div className={styles.area_cont}>
          <textarea
            placeholder="Message"
            {...register("message", { ...validationRules.message })}
          />
          <span className={styles.error}>{errors?.message?.message}</span>
        </div>

        <div className={styles.actions}>
          <div className={styles.spam}>
            <span onClick={toggleCheck}>
              {isChecked ? <Checked /> : <Check />}
            </span>
            <span onClick={toggleCheck}>Don't spam me</span>
          </div>
          <button
            className={styles.button}
            type="submit"
            disabled={isSubmitting}
          >
            message
          </button>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
