import React from "react";
import {
  // DoubleArrow,
  // GreenRectangle,
  RedFlake,
  // SignleArrow,
} from "../../assets";
import PageLayout from "../PageLayout/PageLayout";
import firstFacilities from "../../assets/images/imgs/facilities_2.png";
import booth from "../../assets/images/imgs/facilities_1.png";
import { firstFacilitiesData } from "./FacilitiesFirst.mock";

import styles from "./FacilitiesFirst.module.css";
import BorderedImage from "../BorderedImage/BorderedImage";
import { useDeviceWidth } from "../../hooks";

const FacilitiesFirst = ({ onClick }) => {
  const { windowWidth } = useDeviceWidth();
  return (
    <PageLayout>
      <div className={styles.container}>
        {windowWidth > 1280 && <RedFlake className={styles.flake} />}
        <div className={styles.left}>
          <h2 className={styles.title}>
            {firstFacilitiesData.title}
            <sup>{firstFacilitiesData.sup}</sup>
          </h2>
          <p className={styles.subtitle}>{firstFacilitiesData.subtitle}</p>
          <ol className={styles.list} type="A">
            {firstFacilitiesData.data.map((el) => (
              <li className={styles.list_item} key={el.index}>
                <span className={styles.item_title}>{el.title}</span>
                <span className={styles.item_text}>{el.text}</span>
                {/* <span className={styles.item_tag}>{el.tag}</span> */}
              </li>
            ))}
          </ol>

          {/* <div
            className={styles.actions}
            onClick={onClick}
            role="button"
            tabIndex={0}
          >
            <div className={styles.button}>
              next
              <GreenRectangle className={styles.rect} />
            </div>
            <SignleArrow className={styles.single} />
            <DoubleArrow className={styles.double} />
          </div> */}
        </div>
        <div className={styles.right}>
          <BorderedImage
            src={booth}
            alt="phone booths"
            className={styles.inner_img}
          />
          <span className={styles.letter}>A.</span>
          <img src={firstFacilities} alt="office" className={styles.cover} />
        </div>
      </div>
    </PageLayout>
  );
};

export default FacilitiesFirst;
