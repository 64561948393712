import React from "react";
// import React, { useState } from "react";
// import { Loader } from "../../components";
import {
  FacilitiesFirst,
  // FacilitiesSecond,
  MobileFacilities,
} from "../../components";
import { useDeviceWidth } from "../../hooks";

const FacilitiesPage = () => {
  // const [activeSection, setActiveSection] = useState("first");
  // const [isLoading, setIsLoading] = useState(false);
  const { windowWidth } = useDeviceWidth();

  // const toggleActiveSection = () => {
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     setActiveSection((prevState) =>
  //       prevState === "first" ? "second" : "first"
  //     );
  //   }, 500);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 700);
  // };

  // return (
  //   <>
  //     <Loader isLoading={isLoading} />
  //     {windowWidth > 992 ? (
  //       activeSection === "first" ? (
  //         <FacilitiesFirst onClick={toggleActiveSection} />
  //       ) : (
  //         <FacilitiesSecond onClick={toggleActiveSection} />
  //       )
  //     ) : (
  //       <MobileFacilities />
  //     )}
  //   </>
  // );

  return windowWidth > 992 ? <FacilitiesFirst onClick={() => { }} /> : <MobileFacilities />
};

export default FacilitiesPage;
