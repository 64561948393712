import React, { useState } from "react";
import { GreenSquare } from "../../assets";
import PageLayout from "../PageLayout/PageLayout";
import ServiceCard from "../ServiceCard/ServiceCard";
import { mobileServicesData } from "./MobileServices.mock";

import styles from "./MobileServices.module.css";

const MobileServices = () => {
  const [activeData, setActiveData] = useState(mobileServicesData[0]);

  const handleNavigationClick = (id) => {
    setActiveData(mobileServicesData[id]);
  };

  return (
    <PageLayout>
      <div className={styles.container}>
        <div className={styles.desc}>
          <h1 className={styles.title}>{activeData.mainTitle}</h1>
          <p className={styles.subtitle}>{activeData.subtitle}</p>
          <p className={styles.description}>{activeData.description}</p>
        </div>

        <ServiceCard
          src={activeData.src}
          title={activeData.title}
          desc={activeData.desc}
          price={activeData.price}
          per={activeData.per}
          isMobileCard
          hoverData={activeData.hoverData}
          call={activeData.call}
        />

        <div className={styles.pagination}>
          {mobileServicesData.map((each) => (
            <div
              className={`${styles.page} ${
                each.id === activeData.id ? styles.active : ""
              }`}
              key={each.letter}
              onClick={() => handleNavigationClick(each.id)}
            >
              <GreenSquare className={styles.square} />
              {each.letter}
            </div>
          ))}
        </div>
      </div>
    </PageLayout>
  );
};

export default MobileServices;
