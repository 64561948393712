import React from "react";
import { motion } from "framer-motion";
import styles from "./PageLayout.module.css";

const PageLayout = ({ wrapperClassName = "", className = "", children }) => {
  const pageVariant = {
    unmount: { opacity: 0, delay: 1 },
    mount: { opacity: 1, delay: 1 },
  };

  return (
    <motion.main
      variants={pageVariant}
      initial="unmount"
      animate="mount"
      transition={{ delay: 0.3 }}
      exit="unmount"
      className={`${styles.wrapper} ${wrapperClassName}`}
    >
      <div className={`${styles.container} ${className}`}>{children}</div>
    </motion.main>
  );
};

export default PageLayout;
