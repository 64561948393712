import React from "react";
import PageLayout from "../PageLayout/PageLayout";
import ServiceCard from "../ServiceCard/ServiceCard";

import styles from "./MemeberShips.module.css";
import { DoubleArrow, GreenRectangle, SignleArrow } from "../../assets";
import { flexDeskData, dedicatedDeskData } from "./MemberShips.mock";

const Services = ({ onClick }) => {
  return (
    <PageLayout>
      <div className={styles.container}>
        <div className={styles.desc}>
          <h1>Member</h1>
          <h1>-Ships</h1>
          <h6>Join Hive on your terms</h6>
          <p>
            Whether you’re here for the long run or just passing through. We
            have plans to suit everyone.
          </p>
          <div
            className={styles.actions}
            onClick={onClick}
            role="button"
            tabIndex={0}
          >
            <div className={styles.button}>
              services
              <GreenRectangle className={styles.rect} />
            </div>
            <SignleArrow className={styles.single} />
            <DoubleArrow className={styles.double} />
          </div>
        </div>

        <div className={styles.right}>
          <ServiceCard
            isTall
            src={flexDeskData.src}
            title={flexDeskData.title}
            desc={flexDeskData.desc}
            price={flexDeskData.price}
            per={flexDeskData.per}
          />

          <ServiceCard
            isTall
            src={dedicatedDeskData.src}
            title={dedicatedDeskData.title}
            desc={dedicatedDeskData.desc}
            price={dedicatedDeskData.price}
            per={dedicatedDeskData.per}
            hoverData={dedicatedDeskData.hoverData}
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default Services;
