import React from "react";
import { FbIcon, IgIcon, LnIcon } from "../../assets";

import styles from "./Socials.module.css";

const Socials = ({ className, isWhite = false }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <a
        href="https://www.facebook.com/Hiveco.space"
        target="_blank"
        rel="noreferrer"
        className={`${isWhite ? styles.white : ""}`}
      >
        <FbIcon />
      </a>
      <a
        href="https://www.instagram.com/hive.co.working"
        target="_blank"
        rel="noreferrer"
        className={`${isWhite ? styles.white : ""}`}
      >
        <IgIcon />
      </a>
      <a
        href="https://www.linkedin.com/company/hivee-coworking/?viewAsMember=true"
        target="_blank"
        rel="noreferrer"
        className={`${isWhite ? styles.white : ""}`}
      >
        <LnIcon />
      </a>
    </div>
  );
};

export default Socials;
