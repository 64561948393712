import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { PageLayout } from "../../components";
import styles from "./LocationPage.module.css";
import location from "../../assets/images/imgs/location.png";
import locationMap from "../../assets/images/imgs/location-map.png";
import {
  GreenSquare,
  LeftAngle,
  LocationIcon,
  RightAngle,
  GreenFlake,
} from "../../assets";
import { sliderTextData } from "./LocationPage.mock";
import { useDeviceWidth } from "../../hooks";
import { CONTACT_INFO } from "../../data/ContactInfo/ContactInfo";

const LocationPage = () => {
  const { windowWidth } = useDeviceWidth();
  const [swiper, setSwiper] = useState(undefined);
  const [activeIndex, setActiveIndex] = useState(1);
  const prevRef = useRef();
  const nextRef = useRef();

  useEffect(() => {
    if (swiper?.navigation && swiper?.params) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  return (
    <PageLayout className={styles.container}>
      <div className={styles.description}>
        <h1>PARKS &</h1>
        <h1>TRANSPORT</h1>
        <h1>-ATION</h1>

        {windowWidth > 992 &&
          sliderTextData.map((each) => (
            <div key={each.id} className={styles.card}>
              <h2 className={styles.title}>{each.title}</h2>
              <p className={styles.main}>{each.mainText}</p>
              <p className={styles.secondary}>{each.secondaryText}</p>
            </div>
          ))}
      </div>

      {windowWidth < 992 && (
        <div className={styles.location_swiper}>
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            modules={[Navigation]}
            onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex + 1)}
            onSwiper={setSwiper}
          >
            {sliderTextData.map((each) => (
              <SwiperSlide key={each.id}>
                <div className={styles.location_swiper_slide}>
                  <h2 className={styles.title}>{each.title}</h2>
                  <p className={styles.main}>{each.mainText}</p>
                  <p className={styles.secondary}>{each.secondaryText}</p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className={styles.swiper_navigation}>
            <div ref={prevRef}>
              <LeftAngle />
            </div>
            <span>{`${activeIndex < 9 ? `0${activeIndex}` : activeIndex}/${
              sliderTextData.length < 9
                ? `0${sliderTextData.length}`
                : sliderTextData.length
            }`}</span>
            <GreenSquare className={styles.square} />
            <div ref={nextRef}>
              <RightAngle />
            </div>
          </div>
        </div>
      )}

      {windowWidth > 1220 && <GreenFlake className={styles.flake} />}
      <img src={location} alt="location" className={styles.location_img} />

      <div className={styles.location}>
        {windowWidth > 992 && (
          <div className={styles.location_desc}>
            <p>
              <span>Hive Co.Working</span> 13 Mikheil Tamarashvili.
            </p>
            <p>
              Tbilisi 0162 &nbsp;
              <a href={CONTACT_INFO.map} target="_blank" rel="noreferrer">
                <LocationIcon />
                OPEN MAPS
              </a>
            </p>
          </div>
        )}
        <img src={locationMap} alt="location-map" className={styles.map_img} />
      </div>
    </PageLayout>
  );
};

export default LocationPage;
