import React from "react";
import styles from "./BurgerButton.module.css";

const BurgerButton = ({ isActive, isTiny = false, onClick }) => {
  return (
    <div
      className={`${styles.container} ${isActive && styles.active} ${
        isTiny && styles.tiny
      }`}
      onClick={onClick}
      role="button"
      tabIndex={0}
    >
      <div className={styles.line} />
      <div className={styles.line} />
      <div className={styles.close_line} />
      <div className={styles.line} />
    </div>
  );
};

export default BurgerButton;
