export const validationRules = {
  name: {
    required: "This field is required",
    minLength: {
      value: 3,
      message: "Min 3 characters are required",
    },
  },

  email: {
    required: "This field is required",
    pattern: {
      //eslint-disable-next-line
      value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      message: "Please enter valid email",
    },
  },

  message: {
    required: "This field is required",
    minLength: {
      value: 6,
      message: "Message must be at least 6 characters long",
    },
  },
};
