import firstFacilities from "../../assets/images/imgs/facilities_2.png";
// import secondFacilities from "../../assets/images/imgs/second-facilities.png";
// import ball from "../../assets/images/imgs/ball.png";

export const mobileFacilitiesData = [
  {
    id: 0,
    PAGE_TYPE: "1",
    title: "6,000",
    sup: "m2",
    subtitle: "to meet your work/life balance",
    mainImgSrc: firstFacilities,
    scndImageSrc: "",
    data: [
      {
        index: "A",
        title: "Telephone Booths. ",
        text: "Soundproofed one-person meeting spaces. ",
        tag: "#1300",
      },
      {
        index: "B",
        title: "Kitchens + Dinning. ",
        text: "Separate spaces prep and lunch. ",
        tag: "#2398",
      },
      {
        index: "C",
        title: "Game & Relax zones. ",
        text: "Cosy nooks and entertainment zones. ",
        tag: "#2398",
      },
    ],
  },
  // {
  //   id: 1,
  //   PAGE_TYPE: "2",
  //   title: "WAY UP",
  //   split_title: "TOP",
  //   subtitle: "For extra curricular activites",
  //   mainImgSrc: secondFacilities,
  //   scndImageSrc: ball,
  //   data: [
  //     {
  //       index: "A",
  //       title: "Roof Top Terrace.  ",
  //       text: "Roof Top Terrace. Chillout zone, bar and outdoor event space ",
  //       tag: "#1300",
  //     },
  //     {
  //       index: "B",
  //       title: "Basketball Court. ",
  //       text: "Full size rooftop basketball court. ",
  //       tag: "#2398",
  //     },
  //   ],
  // },
];
